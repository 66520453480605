<template>
  <div class="section-container signin register">
    <v-container>
      <v-row class="signin">
        <v-col cols="12" lg="12" class="right text-center">
          <div class="inner" style="position: relative">
            <v-overlay absolute :value="dataLoading">
              <v-progress-circular indeterminate />
            </v-overlay>
            <div class="text-left d-flex align-center mb-2">
              <v-icon class="black--text" @click="routeBack()"
                >fa fa-arrow-left</v-icon
              >
              <span class="text-h4 black--text font-weight-bold ml-5"
                >Edit User</span
              >
            </div>
            <validation-observer
              ref="registerObserver"
              v-slot="{ handleSubmit }"
            >
              <v-form
                @submit.prevent="handleSubmit(editUser)"
                ref="registerForm"
              >
                <v-row>
                  <v-col cols="12" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="FirstName"
                      rules="required"
                    >
                      <v-text-field
                        :hide-details="!errors.length"
                        class="form-control"
                        v-model="user.firstName"
                        :error-messages="errors"
                        placeholder="FirstName"
                        required
                        outlined
                        dark
                        background-color="#f0f1f6"
                        color="#000"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="LastName"
                      rules="required"
                    >
                      <v-text-field
                        :hide-details="!errors.length"
                        v-model="user.lastName"
                        class="form-control"
                        :error-messages="errors"
                        placeholder="LastName"
                        required
                        outlined
                        dark
                        background-color="#f0f1f6"
                        color="#000"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <validation-provider v-slot="{ errors }" name="Email">
                      <v-text-field
                        v-model="user.email"
                        class="form-control"
                        :error-messages="errors"
                        placeholder="Email"
                        required
                        outlined
                        dark
                        readonly
                        background-color="#f0f1f6"
                        color="#000"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <validation-provider
                  v-slot="{ errors }"
                  name="Address"
                  rules="required"
                >
                  <v-text-field
                    v-model="user.address"
                    class="form-control"
                    :error-messages="errors"
                    placeholder="Address"
                    required
                    outlined
                    dark
                    background-color="#f0f1f6"
                    color="#000"
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="Phone"
                  :rules="{
                    required: true,
                    regex: /^(\d{3}-)\d{3}-\d{4}$/,
                  }"
                >
                  <v-text-field
                    class="form-control"
                    :error-messages="errors"
                    placeholder="Phone"
                    required
                    outlined
                    dark
                    background-color="#f0f1f6"
                    color="#000"
                    maxlength="12"
                    :value="acceptNumber(user.phone)"
                    @keypress="onlyNumbers"
                    @input="user.phone = $event.replaceAll('-', '')"
                    hint="The Phone format is XXX-XXX-XXXX"
                    persistent-hint
                  ></v-text-field>
                </validation-provider>
                <ValidationProvider
                  name="Cards"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-select
                    v-model="user.cardTypeId"
                    :items="cards"
                    required
                    outlined
                    dark
                    placeholder="Cards"
                    class="form-control"
                    background-color="#f0f1f6"
                    color="#000"
                    item-text="cardTypeName"
                    item-value="id"
                    multiple
                    :error-messages="errors"
                    chips
                    deletable-chips
                  >
                    <template v-slot:selection="{ attrs, item, selected }">
                      <v-chip
                        :ripple="false"
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        @click:close="remove(item)"
                      >
                        <strong>{{ item.cardTypeName }}</strong>
                      </v-chip>
                    </template>
                  </v-select>
                </ValidationProvider>
                <validation-provider
                  v-slot="{ errors }"
                  name="Role"
                  rules="required"
                >
                  <v-select
                    :items="roles"
                    v-model="user.roleID"
                    :error-messages="errors"
                    class="form-control"
                    placeholder="Role"
                    item-value="id"
                    item-text="roleName"
                    required
                    outlined
                    dark
                    background-color="#f0f1f6"
                    color="#000"
                    append-icon="fa-caret-down"
                  ></v-select>
                </validation-provider>
                <div class="d-flex justify-space-between">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Role"
                    rules="required"
                  >
                    <v-checkbox
                      v-model="user.active"
                      :error-messages="errors"
                      :true-value="true"
                      :false-value="false"
                      label="Active User"
                      class="mt-n2"
                    ></v-checkbox>
                  </validation-provider>
                  <v-checkbox
                    v-if="user.id && isSuperAdmin"
                    v-model="user.isDelete"
                    :true-value="true"
                    :false-value="false"
                    label="Delete User"
                    class="mt-n2"
                  ></v-checkbox>
                </div>
                <div class="text-center">
                  <v-btn
                    class="signin-btn"
                    type="submit"
                    primary
                    dark
                    rounded
                    :disabled="loading"
                    :loading="loading"
                  >
                    Submit
                  </v-btn>
                </div>
              </v-form>
            </validation-observer>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapState } from "vuex";
import UserService from "@/services/UserService";
import HomeService from "@/services/HomeService";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      user: {
        firstName: "",
        lastName: "",
        email: "",
        address: "",
        phone: "",
        roleID: "",
        active: false,
        isDelete: false,
      },
      loading: false,
      dataLoading: false,
      roles: [],
    };
  },
  async created() {
    this.dataLoading = true;
    await UserService.getSingleUser(this.$route.params.id)
      .then((res) => {
        this.user = {
          firstName: res.data.data.firstName,
          lastName: res.data.data.lastName,
          email: res.data.data.email,
          address: res.data.data.address,
          phone: res.data.data.phone,
          roleID: res.data.data.roleID,
          active: res.data.data.active,
          id: parseInt(this.$route.params.id),
          cardTypeId: res.data.data.cardTypeId,
        };
        this.dataLoading = false;
      })
      .catch((error) => {
        this.dataLoading = false;
        this.$store.dispatch("notifications/setNotification", {
          type: "error",
          text: error.response.data.errorMessage,
          status: error.response.status,
        });
      });
    await HomeService.getRoles()
      .then((res) => {
        this.roles = res.data.data;
      })
      .catch((error) => {
        this.$store.dispatch(
          "notifications/setNotification",
          {
            text: "Error While Fetching Roles",
            type: "error",
            status: error.response.status,
          },
          { root: true }
        );
      });
  },
  async mounted() {
    if (this.cards.length === 0) {
      await this.$store.dispatch("receipt/FETCH_CARDS");
    }
  },
  computed: {
    ...mapState({
      cards: (state) => state.receipt.cards.filter((card) => card.active),
      isSuperAdmin: (state) => state.auth.roleName === "Super Admin",
    }),
  },
  methods: {
    async editUser() {
      this.loading = true;
      await UserService.editUser(this.user)
        .then(() => {
          this.$store.dispatch("SET_USER_EDDITED", true);
          this.loading = false;
          this.$store.dispatch("notifications/setNotification", {
            type: "success",
            text: this.user.isDelete
              ? "User Deleted Successfully"
              : "User Updated Successfully",
          });
          this.$router.push("/admin");
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.$store.dispatch("notifications/setNotification", {
            type: "error",
            text: error.response.data.errorMessage,
            status: error.response.status,
          });
        });
    },
    acceptNumber(value) {
      if (value !== null && value !== "" && value !== 0) {
        const x = String(value)
          .replace(/\D/g, "")
          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        return !x[2]
          ? `${x[1]}`
          : `${x[1]}-${x[2]}${x[3] ? `${"-" + x[3]}` : ""}`;
      }
    },
    onlyNumbers(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },
    remove(item) {
      this.user.cardTypeId.splice(
        this.user.cardTypeId.findIndex((cardId) => {
          return cardId === item.id;
        }),
        1
      );
      this.user.cardTypeId = [...this.user.cardTypeId];
    },
    async routeBack() {
      await this.$store.dispatch("SET_USER_EDDITED", true);
      this.$router.push("/admin");
    },
  },
};
</script>
